@mixin pagination() {
    margin: 1rem 0;
}

@mixin ul-pagination() {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-direction: row;
    margin: 0 -4px;
    margin-left: auto;
}

@mixin li-pagination() {
    padding: 0 4px;

    .page-numbers {
        @include montserrat(700, 0.8rem);
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $color-1;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 2px solid $color-1;
        
        &.current {
            opacity: 0.5;
        }
    }    
}