.page {
	.site-body {
		.content-area {
			.main-area {
				.page-body {
					@include page-body();
				}
			}
		}
	}

	&.contacto {
		.site-body {
			.content-area {
				.main-area {
					.page-body {		
						.subtitle {
							@include subtitle-page-contacto();
						}

						ul.info-contacto {
							@include info-page-contacto();
						}
					}
				}
			}
		}
	}

	&.acerca-de-kashout {
		.site-body {
			.content-area {
				@include content-area-acerca-de-kashout();
				
				.main-area {
					.presentacion {
						@include presentacion-acerca-de-kashout;
					}

					.page-body {
						@include page-body-acerca-de-kashout();
					}
				}
			}
		}
	}

	&.como-funciona {
		.site-body {
			.content-area {
				.main-area {		
					.preguntas-link-group {
						@include preguntas-link-group;
					}

					.detalles {
						@include page-como-funciona-detalles;
					}
				}
			}
		}
	}
}
