nav {
    &.navbar {
        .site-header & {
            @include navbar-header();
            
            .navbar-nav {
                .nav-item {
                    @include navbar-header-item();

                    .nav-link {
                        @include navbar-header-link();
                    }
                }
            }
        }
    }
}
