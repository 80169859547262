.site-header {
    .header-info {
        @include header-info();

        .header-info-wrapper {
            @include header-info-wrapper();

            .logo {
                @include header-logo();
            }

            .primary-navigation {
                @include primary-navigation();
            }            
        } 
    }

    .parallax-header {
        @include parallax-header();
    }    
}