div {
    &.posts {
        @include posts-wrapper();

        .loop-post {
            @include post-loop-wrapper();
        }
    }

    &.instructivo {
        @include instructivo-wrapper();

        .loop-instructivo {
            @include loop-instructivo-wrapper();
        }
    }

    &.nuestros-principios {
        @include nuestros-principios-wrapper;

        .loop-nuestros-principios {
            @include loop-nuestros-principios-wrapper;
        }
    }
}
