form {
	@include form-tag();

	&.search-form {
		@include search-form();

		label {
			@extend .form-group;
		}		

		.search-field {
			@include search-field();
		}

		.search-submit {
			@include search-submit();
		}
	}

	&.contacto {
		label {
			@extend .sr-only;
		}

		.form-control {
			@include form-control();
		}

		.submit-wrapper {
			@include form-contacto-submit-wrapper();
		}
	}
}
