@mixin navbar-header() {
    padding: 0.5rem 0;
}

@mixin navbar-header-item() {
    @include media-breakpoint-down(xs) {
        border-bottom: 1px solid $color-4;
        text-align: center;

        &:last-child {
            border-bottom: none;
        }
    }
}

@mixin navbar-header-link() {
    @include montserrat(400, 0.9rem);
    color: $color-1;
    text-decoration: none;
    padding: 0.2rem 1rem;

    @include media-breakpoint-down(xs) {
        padding: 0.4rem 0;
    }
}