@import url('https://fonts.googleapis.com/css?family=Montserrat|Titillium+Web:300,400,600,700');

@mixin lato($weight, $font-size: 1rem) {
    font-family: 'Lato', sans-serif;
    font-weight: $weight;
    font-size: $font-size;
}

@mixin montserrat($weight, $font-size: 1rem) {
    font-family: 'Montserrat', sans-serif;
    font-weight: $weight;
    font-size: $font-size;
}

@mixin tittilium-web($weight, $font-size: 1rem) {
    font-family: 'Titillium Web', sans-serif;
    font-weight: $weight;
    font-size: $font-size;
}
