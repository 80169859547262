@mixin single-page-body() {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 -0.5rem;

    @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
    }
}

@mixin single-content() {
    @include page-body();
    width: 50%;
    padding: 0 0.5rem;  
    
    @include media-breakpoint-down(xs) {
        width: 100%;
    }
}

@mixin single-img-container() {
    width: 50%;
    padding: 0 0.5rem;
    margin-top: 1rem;

    figure {
        border: 1px solid $color-3;
        border-radius: 5px;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    @include media-breakpoint-down(xs) {
        width: 100%;
    }
}

@mixin single-content-area() {
    &.with-sidebar {
        .main-area {
            .page-body {
                flex-wrap: wrap;
                
                .content {
                    width: 100%;
                }

                .img-container {
                    width: 100%;
                }
            }
        }
    }
}

