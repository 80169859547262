div {
	&.container-fluid {
		&.fixed {
			@include container-fluid-fixed();
		}
	}

	&.site-body {
		.page-template-template-with-image-header & {
			background: linear-gradient(to bottom, $color-7, $color-2 5rem);
			border-top: 2px solid $color-2;
		}

		.content-area {
			@include content-area();
		}

		.static-sidebar-area {
			@include static-sidebar-area();
		}
	}
}

	/*
	&.parallax-header {
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center;
		margin-bottom: 3rem;

		@include media-breakpoint-down(sm) {
			background-size: 130% auto;
		}

		@include media-breakpoint-down(xs) {
			background-size: 160% auto;
		}
		
		@include media-breakpoint-down(xxs) {
			background-size: 250% auto;
		}		

		.page.actividades & {
			margin-bottom: 1.5rem;
		}
	}	

	&.col2-set {
		@extend .row;

		.col-1,
		.col-2,
		.u-column1.col-1,
		.u-column2.col-2 {
			flex: none;
			max-width: 50%;
			padding-left: 15px;
			padding-right: 15px;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&.u-column1 {
		@extend .row;

		&.col-1 {
			flex: none;
			max-width: 50%;
		}
	}

	&.wc-page-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $color-7;
		padding-bottom: 1rem;
		margin-bottom: 1.5rem;

		h1 {
			color: $color-1;
			@include montserrat(700, 1.5rem);
			line-height: 1.8rem;
			margin: 0;
		}
	}

	&.title-wrapper {
		.page-template-template-with-img-header & {
			padding-top: 8rem;
			padding-bottom: 2rem;
			text-align: center;
			background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
			
			h1 {
				@extend .container-fluid;
				@extend .fixed;				
				@include montserrat(700, 2.4rem);
				color: $color-2;
				margin: 0;
			}
		}
	}

	&.site-body {
		.post-type-archive-product &,
		.tax-product_cat & {
			background-color: $color-4;
			padding: 3rem 0;
			padding-top: 0;

			.active-sidebar {
				position: relative;
				@include media-breakpoint-up(sm) {
					display: flex;
					flex-wrap: nowrap;
					flex-direction: row-reverse;
				}

				.main-area {
					
					@include media-breakpoint-down(sm) {
						width: 70%;
						padding-left: 2rem;
					}
					@include media-breakpoint-down(xs) {
						width: 100%;
						padding-left: 0;
					}
					@include media-breakpoint-up(sm) {
						width: 80%;
						padding-left: 2rem;
					}
					padding-right: 0;
				}
		
				.sidebar-area {
					@include media-breakpoint-up(sm) {
						width: 20%;
						display: block;
						padding-right: 1rem;
					}
					@include media-breakpoint-down(sm) {
						width: 30%;
						display: block;
						padding-right: 1rem;
					}
					@include media-breakpoint-down(xs) {
						width: 100%;
						display: none;
						position: absolute !important;
						top: 0;
						left: 0;
						background-color: white;
						z-index: 10;
						box-shadow: 1px 1px 7px rgba(0,0,0, 0.4);
					}				
					
					padding-left: 0;
					
				}				
			}
		}

		.main-area {
			.product {
				&.type-product {
					display: flex;
					flex-direction: row;

					.woocommerce-product-gallery {
						flex-basis: 50%;
						margin-right: 1rem;
					}

					.summary {
						flex-basis: 50%;
						margin-left: 1rem;
					}
				}
			}
		}
	}
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment  {
	border-radius: 0;
	@include montserrat(400, 0.8rem);
	background-color: $color-4;

	.place-order {
		text-align: center;

		.woocommerce-privacy-policy-text {
			text-align: left;
		}
	}
}
*/