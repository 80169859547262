@mixin container-fluid-fixed() {
    max-width: map-get($grid-breakpoints, 'xl');
    padding-left: 15px;
    padding-right: 15px;

    &.px-1rem {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@mixin content-area() {
    display: block;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .page-template-template-homepage & {
        padding-top: 0;
        padding-bottom: 0;
    }    

    &.with-sidebar {
        @include media-breakpoint-up(sm) {
            display: flex;
            flex-wrap: nowrap;
    
            .main-area {
                width: 70%;
                padding-right: 1rem;
            }
    
            .sidebar-area {
                width: 30%;
                padding-left: 1rem;
            }  
            
            &.desktop-left {
                flex-direction: row-reverse;  
                
                .main-area {
                    padding-left: 1rem;
                    padding-right: 0;
                }
        
                .sidebar-area {
                    padding-right: 1rem;
                    padding-left: 0;
                }                
            }            
        }

        @include media-breakpoint-down(xs) {
            display: flex;
            flex-direction: column;

            .sidebar-area {
                margin-top: 2rem;
            }

            &.mobile-top {
                flex-direction: column-reverse;

                .main-area {
                    margin-top: 2rem;
                }

                .sidebar-area {
                    margin-top: 0;
                }
            }
        }
    }
}

@mixin static-sidebar-area() {
    display: block;
}