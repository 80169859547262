
@mixin button(
	$font-size: 0.9rem,
	$font-weight: 700,
	$background-color: $color-1,
	$color: $color-2
) {
	display: inline-block;
	@include montserrat($font-weight, $font-size);
	border-radius: 1.4rem;
	padding: 0.7rem 1.4rem;
	color: $color;
	background-color: $background-color;
	text-decoration: none;
	box-shadow: none !important;
	border: none;

	&:focus {
		box-shadow: none !important;
	}

	&:hover {
		background-color: $background-color;
		color: $color;
	}

	&[disabled] {
		padding: 0.7rem 1.4rem;
		border: none;
		background-color: $background-color;
		color: $color;
		opacity: 0.5;

		&:hover {
			background-color: $background-color;
			color: $color;
		}
	}
}
