a {
    @include links();

    .preguntas-link-group & {
        @include link-outline(0.4rem 0.8rem, 0.9rem, 600, $color-5, $color-1);
        margin: 0.5rem;

        &[data-active="true"] {
            @include link(0.4rem 0.8rem, 0.9rem, 600, $color-5, $color-2); 
        }
    }
}