@mixin links() {
    text-decoration: none;
}

@mixin link($padding, $font-size, $font-weight, $background-color, $font-color, $font-color-hover: $font-color) {
    @extend .btn;
    @extend .btn-secondary;
    @include tittilium-web($font-weight, $font-size);
    padding: $padding;
    background-color: $background-color;
    border: 1px solid $background-color;
    border-radius: 10px;
    color: $font-color;

    &:hover {
        background-color: $background-color;
        color: $font-color-hover;
        border-color: $background-color;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($background-color, .5);
        color: $font-color-hover;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: $background-color;
        border-color: $background-color;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($background-color, .5);
            color: $font-color-hover;
        }
    }
}

@mixin link-outline($padding, $font-size, $font-weight, $background-color, $font-color, $font-color-hover: $font-color) {
    @extend .btn;
    @extend .btn-outline-secondary;    
    @include link($padding, $font-size, $font-weight, $background-color, $font-color, $font-color-hover);
    background-color: transparent;
}