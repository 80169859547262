.pagination {
    @include pagination();

	ul {
		@include ul-pagination();

		li {
			@include li-pagination();
		}
	}
}
