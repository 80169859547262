.loop {
	&.loop-post {
		@include loop-post();
		
		.post-content {
			@include loop-post-content();

			h3 {
				@include loop-post-title();
			}
			
			.post-excerpt {
				@include loop-post-excerpt();
			}

			figure {
				@include loop-post-figure();
			}
		}
	}

	&.loop-instructivo {
		.item-content {
			@include loop-instructivo-content();

			.header {
				@include loop-instructivo-header();

				.icono {
					@include loop-instructivo-icono;
				}

				h3 {
					@include loop-instructivo-title;
				}
			}

			.instrucciones {
				@include loop-instructivo-instrucciones;
			}
		}
	}

	&.loop-nuestros-principios {
		.item-content {
			@include loop-nuestros-principios-content();

			.header {
				@include loop-nuestros-principios-header();

				.icono {
					@include loop-nuestros-principios-icono;
				}

				h3 {
					@include loop-nuestros-principios-title;
				}
			}

			.descripcion {
				@include loop-nuestros-principios-descripcion;
			}			
		}
	}
}
