.section {
    @include section();

    &.slides {
        @include section-slides();
    }

    &.featured-posts {
        @include section-featured-posts();
    }

    &.section-banner { 
        @include section-banner();
    }

    &.instructivo {
        @include section-instructivo();
    }

    &.terminos-y-condiciones {
        @include section-terminos-y-condiciones;        
    }

    &.nuestros-principios {
        @include section-nuestros-principios;
    }
}
