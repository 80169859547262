@mixin form-tag() {
    &.was-validated {
        .form-control {
            &:invalid {
                border: 1px solid map-get($map: $theme-colors, $key: 'danger');
            }

            &:valid {
                border: 1px solid map-get($map: $theme-colors, $key: 'success');
            }
        }
    }
}

@mixin form-control() {
    @extend .form-control;
	@include montserrat(400, 0.8rem);
	background-color: $color-2;
	border: 1px solid $color-7;
	padding: 0.5rem 1rem;
	border-radius: 0;

	&:placeholder-shown {
		@include montserrat(400, 0.8rem);
		color: $color-6;
	}

	&:focus {
		background-color: $color-2;
        box-shadow: 0 0 2px 2px $color-3;
        border-color: $color-7;
    }
    
    &.is-invalid {
        border: 1px solid map-get($map: $theme-colors, $key: 'danger');
    }

    &.is-valid {
        border: 1px solid map-get($map: $theme-colors, $key: 'success');
    }    
}

@mixin form-button() {
	@extend .btn;
	@extend .btn-secondary;
    @include montserrat(400, 0.9rem);
}

@mixin search-form() {
    @include form-tag();
    position: relative;
}

@mixin search-field() {
    @include form-control();
    display: block;
    padding-right: 1rem + 1.8rem;
}

@mixin search-submit() {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    border-radius: 0;
    cursor: pointer;
    height: calc(2.25rem + 2px);
    background-color: transparent;
    width: 1.8rem;
    padding: 0;
    padding-right: 0.5rem;
    border: none;

    &:focus {
        border: none;
        outline: none;
    }

    &:before {
        @extend .fas;
        color: $color-6;
        font-size: 1.1rem;
        content: fa-content($fa-var-search);
    }
}

@mixin form-contacto-submit-wrapper() {
    @include montserrat(400, 0.8rem);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: $color-6;

    button[type="submit"] {
        @include form-button();
        @include tittilium-web(600, 1.2rem);
        border: 1px solid $color-7;
        background-color: $color-2;
        color: $color-8;
        text-transform: uppercase;
        border-radius: 0;
    }    
}
