@mixin section() {
    padding: 2rem 0;

    h2 {
        @include montserrat(700, 1.8rem);
        color: $color-1;
        text-align: center;
        margin-bottom: 2rem;
    }

    header {
        margin-bottom: 2rem;

        h2 {
            margin-bottom: 1rem;
            color: $color-1;
        }

        .subtitle {
            @include montserrat(400, 1rem);
            color: $color-1;
            text-align: center;
        }
    }
}

@mixin section-slides() {
    padding: 0;

    .swiper-slide {
        position: relative;

        .info-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: $color-1, $alpha: 0.3);

            .text-container {
                @include container-fluid-fixed;
                width: 100%;
                text-align: center;
                padding-top: 1.1rem;
                padding-bottom: 1.1rem;
                text-align: right;

                .title {
                    @include montserrat(400, 3rem);
                    color: $color-2;
                    margin: 0;
                    text-transform: uppercase;
                    text-align: right;
                }

                .subtitle {
                    @include montserrat(400, 2rem);
                    color: $color-5;
                    margin: 0;
                    text-transform: uppercase;
                }

                .link {
                    display: inline-block;
                    @include montserrat(400, 1rem);
                    color: $color-2;
                    background-color: $color-5;
                    padding: 0.6rem 2.4rem;
                    text-transform: uppercase;
                    border-radius: 0.5rem;
                    text-decoration: none;
                }
            }
        }

        .img-wrapper {
            overflow: hidden;

            .desktop {
                display: none;
                max-height: 690px;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }

            .tablet {
                display: none;
                max-height: 640px;

                @include media-breakpoint-between(sm, md) {
                    display: block;
                }
            }

            .mobile {
                display: none;
                max-height: 590px;

                @include media-breakpoint-down(xs) {
                    display: block;
                }
            }

            .img-slide {
                width: 100%;
                height: auto;
            }
        }
    }
}

@mixin section-featured-posts() {
    background-color: $color-4;

    .page-template-template-homepage & {
        background-color: $color-2;
    }

    .posts-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0 -5px;

        .loop-post {
            width: 25%;
        }
    }
}

@mixin section-instructivo() {
    padding: 2rem 0;

    .page-template-template-homepage & {
        background: linear-gradient(to bottom, $color-7, $color-2 5rem);
        border-top: 2px solid $color-2;
    }

    .section-title {
        @include tittilium-web(400, 1.6rem);
        margin: 0;
        color: $color-5;
        text-transform: uppercase;

        &.underline {
            &:after {
                content: "";
                display: block;
                margin: 1.5rem auto;
                background-color: $color-5;
                height: 1px;
                max-width: 8rem;
            }
        }

        &:before {
            content: url("../../images/logo.png");
            display: block;
            margin-bottom: 1rem;
        }
    }

    .link-wrapper {
        text-align: center;
        margin-top: 1rem;

        a {
            @include link-outline(
                0.6rem 2rem,
                1.2rem,
                600,
                $color-5,
                $color-5,
                $color-2
            );
        }
    }
}

@mixin section-banner() {
    padding: 0;
    position: relative;
    border-bottom: 2px solid $color-2;

    &:before {
        content: "";
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .paroller {
        //background-size: 100% auto;
        background-size: 110% auto;
        background-repeat: no-repeat;
        background-position: center;

        @include media-breakpoint-down(md) {
            background-size: 125% auto;
        }

        @include media-breakpoint-down(sm) {
            background-size: 150% auto;
        }

        @include media-breakpoint-down(xs) {
            background-size: 175% auto;
        }

        @include media-breakpoint-down(xxs) {
            background-size: 210% auto;
        }

        .banner-wrapper {
            @include container-fluid-fixed;
            margin: 0 auto;
            min-height: 15rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include media-breakpoint-down(sm) {
                background-size: 130% auto;
            }

            @include media-breakpoint-down(xs) {
                background-size: 160% auto;
            }

            @include media-breakpoint-down(xxs) {
                background-size: 250% auto;
            }

            h2 {
                @include tittilium-web(300, 2.6rem);
                text-align: center;
                color: $color-2;
                margin: 0;
                position: relative;
            }

            .link-wrapper {
                text-align: center;

                a {
                    @include link(0.6rem 2rem, 1.2rem, 300, $color-5, $color-2);
                    margin-top: 1.5rem;
                    position: relative;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@mixin section-nuestros-principios {
    .section-title {
        @include tittilium-web(400, 1.6rem);
        margin: 0;
        color: $color-5;
        text-transform: uppercase;

        &.underline {
            &:after {
                content: "";
                display: block;
                margin: 1.5rem auto;
                background-color: $color-5;
                height: 1px;
                max-width: 8rem;
            }
        }

        &:before {
            content: url("../../images/logo.png");
            display: block;
            margin-bottom: 1rem;
        }
    }
}

@mixin section-terminos-y-condiciones() {
    @include page-body();

    .link-wrapper {
        margin-top: 2rem;
        text-align: center;

        a {
            @include montserrat(400, 0.8rem);
            display: inline-block;
            padding: 0.4rem 1.8rem;
            color: $color-2;
            text-transform: uppercase;
            background-color: $color-3;
            border-radius: 16px;
            text-decoration: none;
        }
    }
}
