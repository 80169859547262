@mixin page-title() {
    @include container-fluid-fixed();
    margin: auto;
    margin-top: 1.5rem;
    position: relative;

    h1 {
        @include tittilium-web(600, 2rem);
        color: $color-1;
        margin: 0;
        padding-top: 0;
        position: relative;

        &:after {
            content: '';
            margin: 0.8rem 0;
            margin-bottom: 0;
            border: 1px solid $color-3;
            display: block;
        }
    }

    .page-template-template-homepage & {
        margin: 0;

        h1 {
            @extend .sr-only;
        }
    }

    .page-template-template-with-image-header & {
        margin: 0 auto;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        
        h1 {
            @include container-fluid-fixed();
            margin: 0 auto;
            padding-top: 5rem;
            padding-bottom: 5rem;
            font-weight: 600;
            font-size: 2.4rem;
            color: $color-2;
            text-align: center;

            &:after {
                display: none;
            }
        }
    }
}

@mixin page-title-gradient() {
    display: none;

    .page-template-template-with-image-header & {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
    }
}