// Bootstrap 4 Grid breakpoints
// Define the minimum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
    // Extra extra small screen / small phones
    xxs: 0,
    // Extra small screen / phone
    xs: 420px,
    // Small screen / phone
    sm: 576px,
    // Medium screen / tablet
    md: 768px,
    // Large screen / desktop
    lg: 992px,
    // Extra large screen / wide desktop
    xl: 1368px
);

// Bootstrap 4 Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
    xs: 400px,
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1300px
);

// Custom colors
$color-1: #000;
$color-2: #fff;
$color-3: #999;
$color-4: #e9e9e9;
$color-5: #e86b00;
$color-6: #878787;
$color-7: #c7c7c7;
$color-8: #65c8fa;
$color-8: rgb(104, 104, 104);