@mixin alert() {
    border-radius: 0;
    margin: 0.5rem 0;
    @include page-body();
}

@mixin alert-search-no-result() {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    line-height: 1.6rem;

    &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-exclamation-triangle);
        height: 100%;
        color: map-get($map: $theme-colors, $key: "warning");
        font-size: 1.4rem;
        line-height: 1.6rem;
        margin-right: 0.8rem;     
    }
}