@mixin posts-wrapper() {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -5px;
}

@mixin post-loop-wrapper() {
    flex-basis: 25%;
    max-width: 25%;

    @include media-breakpoint-down(sm) {
        flex-basis: 50%;
        max-width: 50%;                
    }    
}

@mixin instructivo-wrapper() {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 -15px;
}

@mixin loop-instructivo-wrapper() {
    flex-basis: ( 100% / 3 );
    max-width: ( 100% / 3 );
    padding: 15px;
}

@mixin nuestros-principios-wrapper() {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 -15px;
}

@mixin loop-nuestros-principios-wrapper() {
    flex-basis: ( 100% / 3 );
    max-width: ( 100% / 3 );
    padding: 15px;
}