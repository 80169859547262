@mixin site-footer() {
    text-align: center;
    padding: 0;
}

@mixin footer-info-wrapper() {
    background-color: $color-5;
    padding: 3rem 0;
}

@mixin footer-info-items() {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -15px;

    @include media-breakpoint-down(xs) {
        flex-direction: column;
    }
}

@mixin footer-info-item() {
    padding: 0 15px;
    display: inline-flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
        margin-bottom: 1.5rem;
        width: 100%;

        &.nav-shop {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    address {
        display: block;
        margin: 0 -15px;

        span {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            @include tittilium-web(300, 1.2rem);
            color: $color-2;
            padding: 0 15px;

            &:before {
				@extend %fa-icon;
				@extend .fas;
				font-size: 2rem;
                color: $color-2;
                width: 4rem;
                height: 4rem;
                border: 2px solid $color-2;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            &.telephone {
                &:before {
                    content: fa-content($fa-var-phone);
                }
            }

            &.email {
                &:before {
                    content: fa-content($fa-var-envelope);
                }
            }

            .info {
                display: block;

                .fa-at {
                    font-size: 1rem;
                    margin: 0 1px;
                }
            }
        }
    }
}

@mixin footer-info-item-title() {
    @include montserrat(700, 0.8rem);
    color: $color-2;

    &:after {
        content: "";
        display: block;
        height: 1px;
        margin: 0.6rem 0;
        background-color: $color-3;
    }
}

@mixin footer-info-item-menu() {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        @include montserrat(400, 0.7rem);

        a {
            display: inline-block;
            text-decoration: none;
            color: $color-4;
        }

        &:after {
            content: "";
            display: block;
            height: 1px;
            margin: 0.6rem 0;
            background-color: $color-3;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

@mixin footer-info-item-logo-site() {
    img {
        margin-right: auto;

        @include media-breakpoint-down(xs) {
            margin: auto;
        }
    }
}

@mixin footer-info-item-contacto() {
    p {
        margin: 0;
        @include montserrat(400, 0.7rem);
        color: $color-2;
        margin-bottom: 0.5rem;

        span {
            display: block;
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.socials {
            margin-top: 0.5rem;
            margin-bottom: 1rem;

            a {
                text-decoration: none;
                color: $color-2;
                font-size: 1.6rem;
                display: inline-flex;
                align-items: center;
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }

                &:before {
                    @extend %fa-icon;
                    @extend .fab;
                }

                &.facebook {
                    &:before {
                        content: fa-content($fa-var-facebook-square);
                    }
                }

                &.instagram {
                    &:before {
                        content: fa-content($fa-var-instagram);
                    }
                }

                &.linkedin {
                    &:before {
                        content: fa-content($fa-var-linkedin);
                    }
                }

                &.twitter {
                    &:before {
                        content: fa-content($fa-var-twitter);
                    }
                }
            }
        }
    }
}

@mixin footer-info-item-maps() {
    address {
        @include montserrat(400, 0.7rem);
        display: block;
        color: $color-2;
        margin: 0;
        margin-bottom: 0.6rem;
    }

    iframe {
        width: 100% !important;
        height: 12rem;
    }
}

@mixin footer-privacidad-wrapper() {
    padding: 1rem 0;
    @include montserrat(400, .8rem);
    color: #bebdc2;
    text-align: center;
    background-color: #3b3b3b;

    a {
        font-weight: 600;
        color: #bebdc2;

        &:hover {
            color: #868686;
        }
    }    
}

@mixin footer-vendors-logo() {
    @include lato(400, .9rem);
    background-color: #fff;
    color: #b3b3b6;
    text-align: center;
    padding: 1rem 0;

    a {
        color: #b3b3b6;
        text-decoration: none;
    }

    .sep {
        margin: 0 0.2rem;
    }
}

@mixin handheld-footer-bar() {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

@mixin handheld-footer-bar-ul() {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    z-index: 9999;
}

@mixin handheld-footer-bar-li() {
    flex-basis: (100% / 3);
    height: 3.6rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    a {
        color: #404040;
        display: inline-block;

        &:before {
            @extend %fa-icon;
            @extend .fas;
            font-size: 1.5rem;
        }

        &:hover {
            color: #404040;
        }
    }

    &.my-account {
        a {
            &:before {
                content: fa-content($fa-var-user);
            }
        }
    }

    &.home {
        a {
            &:before {
                content: fa-content($fa-var-home);
            }
        }
    }

    &.search {
        a {
            &:before {
                content: fa-content($fa-var-search);
            }
        }
    }

    &.cart {
        a {
            position: relative;

            &:before {
                content: fa-content($fa-var-shopping-cart);
            }

            .count {
                position: absolute;
                top: -0.5rem;
                right: -0.7rem;
                color: #fff;
                width: 1.4rem;
                height: 1.4rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #404040;
                border: 1px solid #fff;
            }
        }
    }

    &.contact {
        a {
            &:before {
                content: fa-content($fa-var-envelope);
            }
        }
    }
}

@mixin handheld-footer-bar-site-search() {
    padding: 0;
    width: 100%;
    height: auto;
    max-height: 0;
    border: none;
    border-radius: 0;
    z-index: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
    background-color: rgba(255, 255, 255, 0.8);
    transition: max-height ease 0.5s, padding ease 0.5s;

    &.active {
        max-height: 6rem;
        padding: 0.8rem;
    }
}

@mixin handheld-footer-bar-search-form() {
    @include search-form();
}

@mixin handheld-footer-bar-search-field() {
    @include search-field();
}

@mixin handheld-footer-bar-search-submit() {
    @include search-submit();
}
