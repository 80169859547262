.single {
	.site-body {
		.content-area {
			@include single-content-area();

			.main-area {
				.page-body {
					@include single-page-body();

					.content {
						@include single-content();
					}

					.img-container {
						@include single-img-container();
					}
				}
			}
		}
	}
}
