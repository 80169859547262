@mixin presentacion-del-sitio() {
	@include montserrat(400, 1.2rem);
	color: $color-1;
	background-color: $color-7;
	text-align: center;
	padding: 2rem 0;

	p {
		margin: 0;
		padding: 0;
	}
}

@mixin page-body() {
	@include tittilium-web(400, 1rem);
	color: $color-1;

	p,
	.wp-block-paragraph {
		@include tittilium-web(400, 1rem);
		margin-bottom: 1.5rem;
	}

	h1 {
		@include tittilium-web(300, 2.3rem);
		box-shadow: none;
	}

	h2 {
		@include tittilium-web(300, 2.2rem);
		color: $color-5;
		margin-bottom: 2rem;
	}

	h3 {
		@include tittilium-web(600, 1.8rem);
		color: $color-5;
		margin-bottom: 1.6rem;		
	}

	h4 {
		@include tittilium-web(400, 1.6rem);
		color: $color-6;
	}

	h5 {
		@include tittilium-web(400, 1.4rem);
		color: $color-6;
	}

	h6 {
		@include tittilium-web(400, 1.2rem);
		color: $color-6;
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			@include tittilium-web(400, 1rem);
			color: $color-1;
			line-height: 1.5rem;
			padding: 0;
			position: relative;
			padding-left: 1.4rem;
			padding-bottom: 0.8rem;

			&:last-child {
				padding-bottom: 0;
			}
			
			&:before {
				@extend %fa-icon;
				@extend .fas;
				content: fa-content($fa-var-angle-right);				
				position: absolute;
				display: inline-flex;
				align-items: center;
				left: 0;
				top: 0;
				height: 1.5rem;
				font-size: 1.5rem;
				color: $color-5;
			}
		}
	}

	ol {
		padding: 0;
		list-style: none;

		li {
			@include tittilium-web(400, 1rem);
			color: $color-1;
			line-height: 1.5rem;
			padding: 0;
			position: relative;
			padding-left: 1.4rem;
			padding-bottom: 0.8rem;	
			
			&:last-child {
				padding-bottom: 0;
			}			

			&:before {
				content: counter() ".";
				position: absolute;
				display: inline-flex;
				align-items: center;
				left: 0;
				top: 0;				
				height: 1.5rem;
				font-size: 1.5rem;
				color: $color-5;
			}
		}
	}

	* {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@mixin subtitle-page-contacto() {
	@include tittilium-web(600, 1.3rem);
	text-align: left;

	&:after {
		content: '';
		display: block;
		height: 1px;
		background-color: $color-7;
		margin: 1.5rem 0;
	}
}

@mixin info-page-contacto() {
	list-style: none;
	padding: 0;
	margin: 0;
	padding-right: 4rem;

	li {
		padding: 0;

		&:before {
			display: none;
			padding: 0;
		}
		
		.li-content {
			display: flex;
			flex-direction: row;
			align-items: center;

			&:before {
				@extend %fa-icon;
				@extend .fas;
				font-size: 1rem;
				width: 2.2rem;
				height: 2.2rem;
				border-radius: 50%;
				color: $color-2;
				background-color: $color-5;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				margin-right: 1rem;
			}			

			.text {
				@include tittilium-web(300, 1rem);
				color: $color-6;
			}

			&.ciudad {
				&:before {
					content: fa-content($fa-var-map-marker-alt);
				}
			}

			&.email {
				&:before {
					content: fa-content($fa-var-envelope);
				}
			}

			&.telefono {
				&:before {
					content: fa-content($fa-var-phone);
				}
			}

			&.facebook {
				&:before {
					@extend .fab;
					display: inline-flex;
					align-items: center;
					justify-content: center;					
					content: fa-content($fa-var-facebook-f);
				}
			}

			&.instagram {
				&:before {
					@extend .fab;
					content: fa-content($fa-var-instagram);
				}
			}

			&.linkedin {
				&:before {
					@extend .fab;
					content: fa-content($fa-var-linkedin);
				}
			}

			&.twitter {
				&:before {
					@extend .fab;
					content: fa-content($fa-var-twitter);
				}
			}
		}

		&:after {
			content: '';
			display: block;
			height: 1px;
			background-color: $color-7;
			margin: 1rem 0;
			margin-left: 2.2rem + 1rem;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}

@mixin content-area-acerca-de-kashout {
	padding-bottom: 0;	
}

@mixin presentacion-acerca-de-kashout {
	@include page-body();
	padding-top: 1rem;
}

@mixin page-body-acerca-de-kashout {
	background-color: $color-4;
	padding: 3rem 0;
}

@mixin preguntas-link-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin: -0.5rem;
}

@mixin page-como-funciona-detalles {
	margin: 3rem 0;

	@keyframes fadeEffect {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.detalle {
		@include page-body;
		animation: fadeEffect 1s ease-in-out;

		&[data-show="false"] {
			display: none;
		}

		&[data-show="true"] {
			display: block;		
		}

		&.faqs {
			@include page-como-funciona-detalle-faqs;
		}
	}
}

@mixin page-como-funciona-detalle-faqs {
	#accordion-faqs {
		.card {
			border: 2px solid $color-4;
			margin-bottom: 1rem;
			border-radius: 0.5rem;

			.card-header {
				background-color: $color-4;
				border: 0;
				padding: 0;

				h5 {
					.btn.btn-link {
						@include tittilium-web(300, 1.4rem);
						width: 100%;
						color: $color-8;
						text-decoration: none;
						padding: 0.7rem 1.4rem;
						text-align: left;
					}
				}
			}
		}
	}
}