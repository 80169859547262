@mixin loop-post() {
    padding: 5px;
}

@mixin loop-post-content() {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

@mixin loop-post-title() {
    @include montserrat(700, 1.2rem);
    color: $color-1;
    order: 2;
    margin-top: 1rem;

    a {
        color: $color-1;
        text-decoration: none;
    }
}

@mixin loop-post-excerpt() {
    @include montserrat(400, 0.8rem);
    color: $color-3;
    order: 3;
    margin: 0;
}

@mixin loop-post-figure() {
    order: 1;
    margin: 0;
    max-height: 400px;
    overflow: hidden;
}

/* Mixins loop instructivo */

@mixin loop-instructivo-content() {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

@mixin loop-instructivo-header() {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

@mixin loop-instructivo-icono() {
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin loop-instructivo-title() {
    @include tittilium-web(400, 1.3rem);
    text-align: center;
    text-transform: uppercase;
    margin: 1rem 0;

    .first-word {
        color: $color-5;
    }
}

@mixin loop-instructivo-instrucciones() {
    @include page-body;
    padding: 1.5rem;
    background-color: $color-4;
    border-radius: 10px;
    height: 12rem;

    .simplebar-scrollbar {
        &:before {
            background-color: $color-5;
        }
    }

    font-size: 0.85rem;

    p,
    .wp-block-paragraph {
        font-size: 0.85rem;
        margin-bottom: 0.5rem;
    }
    
    ul,
    ol {
        li {
            font-size: 0.85rem;
            padding-left: 1rem;
            padding-bottom: 0;
            line-height: 1.3rem;

            &:before {
                font-size: 1rem;
                height: 1.3rem;
            }
        }
    }
}

/* Mixins loop nuestros principios */

@mixin loop-nuestros-principios-content() {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

@mixin loop-nuestros-principios-header() {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

@mixin loop-nuestros-principios-icono() {
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin loop-nuestros-principios-title() {
    @include tittilium-web(400, 1.3rem);
    text-align: center;
    text-transform: uppercase;
    margin: 1rem 0;
}

@mixin loop-nuestros-principios-descripcion() {
    @include page-body;
    padding: 1.5rem;
    background-color: $color-4;
    border-radius: 10px;
    height: 10rem;

    .simplebar-scrollbar {
        &:before {
            background-color: $color-5;
        }
    }

    font-size: 0.85rem;

    p,
    .wp-block-paragraph {
        font-size: 0.85rem;
        margin-bottom: 0.5rem;
    }
    
    ul,
    ol {
        li {
            font-size: 0.85rem;
            padding-left: 1rem;
            padding-bottom: 0;
            line-height: 1.3rem;

            &:before {
                font-size: 1rem;
                height: 1.3rem;
            }
        }
    }
}