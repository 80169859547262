@mixin sidebar-widget() {
    padding: 0;
    margin: 0;
    margin-bottom: 1.5rem;
    color: $color-1;
    @include montserrat(400, 0.7rem);

    h3 {
        @include montserrat(700, 1rem);
    }

    ul {
        list-style: none;
        padding: 0;
    }    

    li {
        a {
            color: $color-1;
        }
    }
}