@mixin modal() {
    .modal-dialog {
        .modal-content {
            .modal-header {
                .modal-title {
                    @include montserrat(700, 1.4rem);
                    font-weight: 700 !important;
                    color: $color-1 !important;
                }
            }

            .modal-body {
                @include montserrat(400, 0.9rem);
                color: $color-1;
            }
        }
    }
}

@mixin modal-type($type) {
    .modal-dialog {
        .modal-content {
            border-color: map-get($map: $theme-colors, $key: $type) !important;

            .modal-header {
                .modal-title {
                    color: map-get($map: $theme-colors, $key: $type) !important;
                }
            }
        }
    }
}