.site-footer {
	@include site-footer();

	.handheld-footer-bar {
		@include handheld-footer-bar();

		ul {
			@include handheld-footer-bar-ul();

			li {
				@include handheld-footer-bar-li();
			}
		}

		.site-search {
			@include handheld-footer-bar-site-search();
	
			.woocommerce-product-search,
			.search-form {
				@include handheld-footer-bar-search-form();

				.search-field {
					@include handheld-footer-bar-search-field();
				}

				.search-submit {
					@include handheld-footer-bar-search-submit();
				}
			}
		}		
	}

	.info-wrapper {
		@include footer-info-wrapper();

		.items {
			@include footer-info-items();

			.item {
				@include footer-info-item();

				h3 {
					@include footer-info-item-title();
				}

				ul {
					&.menu-footer {
						@include footer-info-item-menu();
					}
				}
				
				&.logo-site {
					@include footer-info-item-logo-site();
				}	
				
				&.info-contacto {
					@include footer-info-item-contacto();
				}

				&.maps {
					@include footer-info-item-maps();
				}
			}
		}
	}

	.privacidad-wrapper {
		@include footer-privacidad-wrapper();
	}	

	.vendors-logos {
		@include footer-vendors-logo();
	}
}
