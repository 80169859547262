@mixin page-body-404() {
    text-align: center;

    .big-text {
        @include montserrat(400, 1.7rem);
        color: $color-1;
        margin: 0;
    }

    .img-container {
        max-width: 50rem;
        margin: 2rem auto;
        box-shadow: 0 0 1rem $color-3;
        
        figure {
            img {
                max-width: 100%;
            }
        }
    }

    .medium-text {
        @include montserrat(400, 1.5rem);
        color: $color-1;
        margin: 0;
    }
}