@mixin header-info {
    background-color: $color-2;
    padding: 0.5rem 0;
}

@mixin header-info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
}

@mixin header-logo {
    @include media-breakpoint-down(sm) {
        flex-basis: 100%;
        text-align: center;
    }

    a {
        display: inline-block;

        img {
            max-height: 6rem;
        }
    }
}

@mixin primary-navigation {
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
        margin-top: 0.5rem;
        flex-basis: 100%;
    }
}

@mixin parallax-header {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center top;

    @include media-breakpoint-down(md) {
        background-size: 120% auto;
    }

    @include media-breakpoint-down(sm) {
        background-size: 140% auto;
    }
    
    @include media-breakpoint-down(xs) {
        background-size: 160% auto;
    } 
    
    @include media-breakpoint-down(xxs) {
        background-size: 180% auto;
    }    
}